import React from "react";
import { useParams } from "react-router-dom";
import Product from "../../components/Products/Product/Product";
import { ProductsContainer } from "../../components/Products/Products.styled";
import { useStateContext } from "../../context/StateContext";
import {
  ProductListContainer,
  ProductListFilterContainer,
  ProductListTitle,
} from "./ProductListpage.styled";

const ProductCategoryPage = () => {
  const { categories, collections } = useStateContext();
  const { categoryName, collectionName } = useParams();
  const currentCategory = categories.find(
    (category) => category.categoryName === categoryName
  );
  console.log(currentCategory);
  const collectionProducts = collections.find(
    (collection) => collection.collectionName === collectionName
  );
  console.log(collectionProducts);
  return categoryName ? (
    <ProductListContainer>
      <ProductListFilterContainer></ProductListFilterContainer>
      <ProductListTitle>
        {categoryName
          .split("-")
          .map((word) => word.toUpperCase())
          .join(" ")}
      </ProductListTitle>

      <ProductsContainer paddingTop="50">
        {currentCategory?.products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </ProductsContainer>
    </ProductListContainer>
  ) : (
    <ProductListContainer>
      <ProductListFilterContainer>
        {/* <ProductListFilter>
          <ProductListFilterText>Sort Products:</ProductListFilterText>
          <ProductListSelect>
            <ProductListOption selected>Newest</ProductListOption>
            <ProductListOption>Price (asc)</ProductListOption>
            <ProductListOption>Price (desc)</ProductListOption>
          </ProductListSelect>
        </ProductListFilter> */}
      </ProductListFilterContainer>
      <ProductListTitle>
        {collectionProducts.collectionName
          .split("-")
          .map((word) => word.toUpperCase())
          .join(" ")}
      </ProductListTitle>

      <ProductsContainer paddingTop="50">
        {collectionProducts.products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </ProductsContainer>
    </ProductListContainer>
  );
};

export default ProductCategoryPage;
