import styled from "styled-components";
import { mobileView, tabView } from "../../global/responsive";

export const FooterContainer = styled.div`
  width: 100%;
  padding: 20px !important;
  display: flex;
  background: #000;
  color: #b8a06a;
  ${mobileView({ flexDirection: "column" })};
  ${tabView({ flexDirection: "column" })};
`;

export const FooterLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px !important;
`;

export const FooterLogo = styled.h1``;

export const FooterDesc = styled.p`
  margin: 30px 0px !important;
`;

export const FooterSocialContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const FooterSocialIcon = styled.a`
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 10px !important;
  color: #b8a06a;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #b8a06a;
  cursor: pointer;
  &:hover {
    border-radius: 50% !important;
    transition: 0.8s ease !important;
    color: #b8a06a;
  }
`;

export const FooterCenter = styled.div`
  flex: 1;
  padding: 50px 20px !important;
  ${mobileView({ display: "none" })}
`;

export const FooterTitle = styled.h3`
  margin-bottom: 30px;
`;

export const FooterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0px !important;
`;

export const FooterListItem = styled.li`
  width: 50%;
`;

export const FooterRight = styled.div`
  flex: 1;
  padding: 50px 20px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
`;

export const FooterContactItem = styled.div`
  display: inline-flex !important;
  align-items: center;
  gap: 15px;
`;

export const FooterPayment = styled.img`
  width: 50%;
`;
export const StyleGuideContainer = styled.div`
  width: 100%;
  padding: 20px !important;
  text-align: center;
  background: #000;
  color: #b8a06a;
  ${mobileView({ flexDirection: "column" })};
  ${tabView({ flexDirection: "column" })};
`;
export const FooterStyleGuideTitle = styled.h2`
  margin-top: 20px !important;
  text-align: center;
  margin-bottom: 25px !important;
`;
export const FooterStyleGuideImage = styled.img``;
