import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import {
  SliderArrow,
  SliderButton,
  SliderContainer,
  SliderDesc,
  SliderImage,
  SliderImgContainer,
  SliderInfoContainer,
  SliderSlide,
  SliderTitle,
  SliderWrapper,
} from "./Slider.styled";

const Slider = () => {
  const { banners } = useStateContext();
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction, length) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : length - 1);
    } else {
      setSlideIndex(slideIndex < length - 1 ? slideIndex + 1 : 0);
    }
  };
  const navigate = useNavigate();
  return (
    <SliderContainer>
      <SliderArrow
        direction="left"
        onClick={() => handleClick("left", banners?.length)}
      >
        <ArrowLeftOutlined />
      </SliderArrow>
      <SliderWrapper slideIndex={slideIndex}>
        {banners?.map((item) => (
          <SliderSlide key={item.id}>
            <SliderImgContainer>
              <SliderImage src={`${item.bannerImages?.[0]}`} />
            </SliderImgContainer>
            <SliderInfoContainer>
              <SliderTitle>{item.bannerName}</SliderTitle>
              <SliderDesc>{item.bannerDescription}</SliderDesc>
              <SliderButton
                onClick={() => navigate(`/collection/${item.bannerCollection}`)}
              >
                SHOP NOW
              </SliderButton>
            </SliderInfoContainer>
          </SliderSlide>
        ))}
      </SliderWrapper>
      <SliderArrow
        direction="right"
        onClick={() => handleClick("right", banners?.length)}
      >
        <ArrowRightOutlined />
      </SliderArrow>
    </SliderContainer>
  );
};

export default Slider;
