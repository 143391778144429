import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CollectionItemButton,
  CollectionItemContainer,
  CollectionItemImage,
  CollectionItemInfo,
  CollectionItemTitle,
} from "./CollectionItem.styled";

const CollectionItem = ({ collection }) => {
  const navigate = useNavigate();
  return (
    <CollectionItemContainer>
      <CollectionItemImage
        src={`${collection.products?.[0].productImages?.[0]}`}
      />
      <CollectionItemInfo>
        <CollectionItemTitle>
          {
            collection.collectionName
              .split("-") // split the string into an array of words
              .map((word) => word.toUpperCase()) // capitalize the first letter of each word
              .join(" ") // join the words back into a single string with a space separator}
          }{" "}
        </CollectionItemTitle>
        <CollectionItemButton
          onClick={() => navigate(`/collection/${collection.collectionName}`)}
        >
          SHOP NOW
        </CollectionItemButton>
      </CollectionItemInfo>
    </CollectionItemContainer>
  );
};

export default CollectionItem;
