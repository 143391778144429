import styled from "styled-components";
import { mobileView, tabView } from "../../global/responsive";

export const CollectionItemContainer = styled.div`
  margin: 3px;
  width: 360px;
  height: 70vh;
  position: relative;
  ${tabView({ width: "80%", margin: "0px auto !important" })}
  ${mobileView({ width: "95%" })}
`;

export const CollectionItemImage = styled.img`
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  ${mobileView({ height: "100% !important", width: "100% !important" })}
`;

export const CollectionItemInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  background: #000;
  opacity: 0.7;
`;

export const CollectionItemTitle = styled.h1`
  color: #b8a06a;
  margin-bottom: 20px !important;
  opacity: 1 !important;
`;

export const CollectionItemButton = styled.button`
  padding: 8px 16px !important;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  color: #b8a06a;
  border: 3px #b8a06a solid;
`;
