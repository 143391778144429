import React from "react";
import { useStateContext } from "../../context/StateContext";
import CategoryItem from "../Categoryitem/CategoryItem";
import { CategoriesContainer } from "./Categories.styled";

const Categories = () => {
  const { categories } = useStateContext();
  console.log(categories);
  return (
    <CategoriesContainer>
      {categories.map((category) => {
        return <CategoryItem key={category.id} category={category} />;
      })}
    </CategoriesContainer>
  );
};

export default Categories;
