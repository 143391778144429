// import Swiper core and required modules
import { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Product from "../Products/Product/Product";

export const SwiperSlider = ({ products }) => {
  return (
    <>
      <Swiper
        modules={[Autoplay, A11y]}
        autoHeight={true}
        effect={"slide"}
        breakpointsBase={"container"}
        breakpoints={{
          760: {
            slidesPerView: 2,
            spaceBetween: 50,
            centeredSlides: true,
            centerInsufficientSlides: true,
            centeredSlidesBounds: true,
          },
          980: {
            slidesPerView: 3,
            spaceBetween: 50,
            centeredSlides: true,
            centerInsufficientSlides: true,
            centeredSlidesBounds: true,
          },
        }}
        autoplay={{
          delay: 1500,
          disableOnInteraction: true,
        }}
        slidesPerView={"auto"}
        loop={true} // Set loop to true
      >
        {products?.map((product) => (
          <SwiperSlide
            key={product.id}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Product product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
