import { Link } from "react-router-dom";
import styled from "styled-components";

export const ProductLink = styled(Link)`
  text-decoration: none !important;
  max-width: 270px;
`;
export const ProductInfo = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  gap: 8px;
  cursor: pointer;
`;

export const ProductContainer = styled.div`
  flex: 1;
  margin: 5px !important;
  min-width: 270px;
  max-width: 270px;
  height: 350px;
  padding: 30px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background-color: #b8a06a;
  position: relative;

  &:hover ${ProductInfo} {
    opacity: 1;
  }
`;
export const ProductImgContainer = styled.div`
  height: 80%;
  width: 80%;
  object-fit: cover;
`;
export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const ProductIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;
export const ProductInfoContainer = styled.div`
  width: 80%;
  gap: 10px;
  color: #000 !important;
`;
export const ProductName = styled.h5`
  font-weight: 800;
  color: #000 !important;
`;
export const ProductPrice = styled.h5`
  font-weight: 600;
  color: #000 !important;
`;
