import { Button, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useStateContext } from "../../context/StateContext";
import { tabView } from "../../global/responsive";
const Container = styled.div`
  margin: 30px auto !important;
  width: 60%;
  padding: 10px !important;
  ${tabView({ width: "80% !important" })};
`;
const CheckoutPaper = styled(Paper)`
  padding: 25px 20px !important;
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0px !important;
  gap: 15px;
`;
const CheckoutButton = styled(Button)`
  color: #b8a06a !important;
  background: black !important;
  &:disabled {
    color: #000 !important;
    background: #b8a06a !important;
  }
`;

const CheckoutForm = () => {
  const [deliveryType, setDeliveryType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [paystackDisabled, setPaystackDisabled] = useState(true);
  const [shippingFee, setShippingFee] = useState(0);
  const [pickupLocation, setPickupLocation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const {
    totalPrice,
    setCartItems,
    setTotalPrice,
    setTotalQuantities,
    cartItems,
    taxAndVat,
    deliveryPrices,
  } = useStateContext();
  let finalTotalPrice = totalPrice + taxAndVat;
  // const handleMailToAdmin = async (ref) => {
  //   try {
  //     const res = await fetch(`${serverUrl}/api/mail/admin-mail`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         phone: userInfo.phoneNumber,
  //         email: userInfo.emailAddress,
  //         orderId: `kaiz${ref}`,
  //         cartItems: cartItems,
  //         subTotal: finalTotalPrice,
  //         totalPrice: +finalTotalPrice + +shippingFee,
  //         shippingFee: shippingFee,
  //         pickupLocation: pickupLocation,
  //         address: `${userInfo.address}  ${userInfo.city}  ${userInfo.state}`,
  //         deliveryType: deliveryType,
  //       }),
  //     });
  //     return res;
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // const handleCustomerInfoToAdmin = async (ref) => {
  //   try {
  //     const res = await fetch(
  //       `${serverUrl}/api/mail/customer-details-to-admin`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           phone: userInfo.phoneNumber,
  //           email: userInfo.emailAddress,
  //           orderId: `kaiz${ref}`,
  //           address: `${userInfo.address}  ${userInfo.city}  ${userInfo.state}`,
  //           deliveryType: deliveryType,
  //           marketerCode: userInfo.marketerCode,
  //         }),
  //       }
  //     );
  //     return res;
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // const handleMail = async (ref) => {
  //   try {
  //     const res = await fetch(`${serverUrl}/api/mail/customer-mail`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: userInfo.emailAddress,
  //         orderId: `kaiz${ref}`,
  //         cartItems: cartItems,
  //         subTotal: finalTotalPrice,
  //         totalPrice: finalTotalPrice + shippingFee,
  //         shippingFee: shippingFee,
  //         pickupLocation: pickupLocation,
  //         address: `${userInfo.address}  ${userInfo.city}  ${userInfo.state}`,
  //       }),
  //     });
  //     return res;
  //   } catch (error) {
  //     console.log(error.error);
  //   }
  // };
  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    // handleMail(reference.reference);
    // handleMailToAdmin(reference.reference);
    // handleCustomerInfoToAdmin(reference.reference);
    setCartItems([]);
    setTotalQuantities(0);
    setTotalPrice(0);
    localStorage.clear();
    navigate("/");
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    navigate("/checkout");
    setPaystackDisabled(false);
  };
  const shippingStates = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Outside Nigeria",
  ];
  // const deliveryPrices = deliveryPrices;
  console.log(deliveryPrices);

  const deliveryOptions = [
    { deliveryType: "Select Delivery", disabled: true },
    {
      deliveryType: "Pickup (Available in Lagos and Ibadan Only)",
      disabled: false,
    },
    { deliveryType: "Home Delivery", disabled: false },
  ];
  const pickupOptions = [
    { name: "Select Pickup", disabled: true, selected: true },
    {
      name: "Head Office: University of Lagos",
      disabled: false,
      selected: false,
    },
    {
      name: "Sub Office: Kuola Road , Ibadan",
      disabled: false,
      selected: false,
    },
  ];
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    city: "",
    state: "",
    deliveryType: "",
    marketerCode: "",
  });
  // const [config, setConfig] = useState({
  //   reference: new Date().getTime().toString(),
  //   firstname: userInfo.firstName,
  //   lastname: userInfo.lastName,
  //   phone: userInfo.phoneNumber,
  //   email: "",
  //   amount: 0,
  //   publicKey: process.env.REACT_APP_KAIZEN_BRAND_PUBLIC_KEY,
  // });
  const [config, setConfig] = useState({
    reference: new Date().getTime().toString(),
    firstname: userInfo.firstName,
    lastname: userInfo.lastName,
    phone: userInfo.phoneNumber,
    email: "",
    amount: 0,
    publicKey: process.env.REACT_APP_KAIZEN_BRAND_PUBLIC_KEY,
    metadata: {
      orderId: `kaiz${new Date().getTime().toString()}`,
      deliveryType: userInfo.deliveryType,
      pickupLocation: pickupLocation,
      shippingFee: shippingFee,
      cartItems: cartItems,
      firstname: userInfo.firstName,
      lastname: userInfo.lastName,
      phone: userInfo.phoneNumber,
      email: userInfo.emailAddress,
      marketerCode: userInfo.marketerCode,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userInfo.deliveryType === "Home Delivery") {
      let deliveryFee;

      switch (userInfo.deliveryChoice) {
        case "1700":
          deliveryFee = 1700;
          break;
        case "2500":
          deliveryFee = 2500;
          break;
        case "4000":
          deliveryFee = 4000;
          break;
        case "5000":
          deliveryFee = 5000;
          break;
        case "10000":
          deliveryFee = 10000;
          break;
        default:
          return;
      }

      if (deliveryFee) {
        const priceInPaystack = finalTotalPrice * 100;
        const totalAmount = priceInPaystack + deliveryFee * 100;

        setShippingFee(deliveryFee); // Update state for other components
        setIsDisabled(true);
        setPaystackDisabled(false);

        setConfig({
          ...config,
          email: userInfo.emailAddress,
          amount: totalAmount,
          metadata: {
            ...config.metadata,
            firstname: userInfo.firstName,
            lastname: userInfo.lastName,
            phone: userInfo.phoneNumber,
            email: userInfo.emailAddress,
            cartItems: cartItems,
            subTotal: finalTotalPrice,
            totalPrice: finalTotalPrice + deliveryFee,
            shippingFee: deliveryFee,
            pickupLocation: pickupLocation,
            address: `${userInfo.address} ${userInfo.city} ${userInfo.state}`,
            marketerCode: userInfo.marketerCode,
            deliveryType: userInfo.deliveryType,
          },
        });
      }
    } else if (
      userInfo.deliveryType === "Pickup (Available in Lagos and Ibadan Only)"
    ) {
      let pickupLocation;

      switch (userInfo.deliveryChoice) {
        case "Sub Office: Kuola Road , Ibadan":
          pickupLocation = "Sub Office: Kuola Road , Ibadan";
          break;
        case "Head Office: University of Lagos":
          pickupLocation = "Head Office: University of Lagos";
          break;
        default:
          return;
      }

      if (pickupLocation) {
        setPickupLocation(pickupLocation);
        setIsDisabled(true);
        setPaystackDisabled(false);

        const priceInPaystack = finalTotalPrice * 100;

        setConfig({
          ...config,
          email: userInfo.emailAddress,
          amount: priceInPaystack,
          metadata: {
            ...config.metadata,
            firstname: userInfo.firstName,
            lastname: userInfo.lastName,
            phone: userInfo.phoneNumber,
            email: userInfo.emailAddress,
            cartItems: cartItems,
            subTotal: finalTotalPrice,
            totalPrice: finalTotalPrice,
            pickupLocation: pickupLocation,
            address: `${userInfo.address} ${userInfo.city} ${userInfo.state}`,
            deliveryType: userInfo.deliveryType,
          },
        });
      }
    }
    console.log(userInfo);
  };

  let initializePayment = usePaystackPayment(config);
  const handleMarketerInputChange = (event) => {
    const { value } = event.target;

    // Regular expression to match alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    if (value.length > 6 || !alphanumericRegex.test(value)) {
      // Display error message if input is invalid
      setErrorMessage("Maximum 6 alphanumeric characters allowed");
      setUserInfo({
        ...userInfo,
        marketerCode: value.slice(0, 6),
      }); // Truncate input to maximum 6 characters
    } else {
      // Clear error message if input is valid
      setErrorMessage("");
      setUserInfo({
        ...userInfo,
        marketerCode: value,
      });
    }
  };
  return (
    <Container>
      <CheckoutPaper variant="outlined">
        <Typography variant="h4" className="mb-3" align="center">
          Checkout
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Row className="gap-4 mt-2">
            <Col>
              <Form.Control
                className="p-2"
                placeholder="First name"
                type="text"
                required
                value={userInfo.firstName}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, firstName: e.target.value });
                }}
              />
            </Col>
            <Col>
              <Form.Control
                className="p-2"
                placeholder="Last name"
                type="text"
                required
                value={userInfo.lastName}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, lastName: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row className="gap-4 mt-2">
            <Col>
              <Form.Control
                className="p-2"
                placeholder="Phone Number"
                type="number"
                maxLength={11}
                pattern="[7-9]{1}[0-9]{9}"
                min="11"
                required
                value={userInfo.phoneNumber}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, phoneNumber: e.target.value });
                }}
              />
            </Col>
            <Col>
              <Form.Control
                className="p-2"
                placeholder="Email"
                type="email"
                required
                value={userInfo.emailAddress}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, emailAddress: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Form.Control
            className="mt-2 p-2"
            placeholder="Address"
            type="text"
            required
            value={userInfo.address}
            onChange={(e) => {
              setUserInfo({ ...userInfo, address: e.target.value });
            }}
          />
          <Row className="gap-4 mt-2">
            <Col>
              <Form.Control
                className="p-2"
                placeholder="City"
                type="text"
                required
                value={userInfo.city}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, city: e.target.value });
                }}
              />
            </Col>
            <Col>
              <Form.Select
                aria-label="Select State"
                className="p-2"
                placeholder="State"
                required
                value={userInfo.state}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, state: e.target.value });
                }}
              >
                {shippingStates.map((state) => (
                  <option key={state}>{state}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <>
            <label htmlFor="" className="mt-2">
              Delivery Type
            </label>
            <Form.Select
              aria-label="Select Delivery Type"
              className="mt-1 p-2"
              placeholder="Delivery Type"
              defaultValue={deliveryOptions[0].deliveryType}
              required
              onChange={(e) => {
                setDeliveryType(e.target.value);
                setUserInfo({ ...userInfo, deliveryType: e.target.value });
              }}
            >
              {deliveryOptions.map((option) => (
                <option
                  key={option.deliveryType}
                  disabled={option.disabled}
                  value={option.deliveryType}
                >
                  {option.deliveryType}
                </option>
              ))}
            </Form.Select>

            {/* Conditional Rendering Using Switch */}
            {(() => {
              switch (deliveryType) {
                case "Pickup (Available in Lagos and Ibadan Only)":
                  return (
                    <Form.Select
                      aria-label="Select Delivery Type"
                      className="mt-1 p-2"
                      placeholder="Pick up"
                      defaultValue={pickupOptions[0].name}
                      required
                      onChange={(e) => {
                        console.log(e.target.value);
                        setUserInfo({
                          ...userInfo,
                          deliveryChoice: e.target.value,
                        });
                      }}
                    >
                      {pickupOptions.map((location) => (
                        <option
                          key={location.name}
                          disabled={location.disabled}
                          value={location.name}
                          selected={location.selected}
                        >
                          {location.name}
                        </option>
                      ))}
                    </Form.Select>
                  );

                case "Home Delivery":
                  return (
                    <Form.Select
                      aria-label="Select Delivery Type"
                      className="mt-1 p-2"
                      placeholder="Delivery Type"
                      defaultValue={deliveryPrices[0]?.priceName}
                      required
                      onChange={(e) => {
                        console.log(e.target.value);
                        setUserInfo({
                          ...userInfo,
                          deliveryChoice: e.target.value,
                        });
                      }}
                    >
                      {deliveryPrices.map((fee) => (
                        <option
                          key={fee?.priceName}
                          value={fee?.priceAmount}
                          disabled={fee?.disabled}
                          selected={fee?.selected}
                        >
                          {`${fee?.priceName} ${
                            fee.priceAmount ? fee.priceAmount : ""
                          }`}
                        </option>
                      ))}
                    </Form.Select>
                  );

                default:
                  return <p>Please select a delivery type.</p>;
              }
            })()}
          </>
          <Form.Control
            className="mt-2 p-2"
            placeholder="Marketer's Code"
            type="text"
            max={6}
            value={userInfo.marketerCode}
            onChange={handleMarketerInputChange}
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <ButtonsContainer>
            <CheckoutButton
              className="mt-3 p-2"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isDisabled}
            >
              Submit
            </CheckoutButton>
            <CheckoutButton
              className="mt-3 p-2"
              variant="contained"
              color="primary"
              type="submit"
              disabled={paystackDisabled}
              onClick={() => {
                initializePayment(onSuccess, onClose);
                setPaystackDisabled((prevState) => !prevState);
              }}
            >
              Pay
            </CheckoutButton>
          </ButtonsContainer>
        </Form>
      </CheckoutPaper>
    </Container>
  );
};

export default CheckoutForm;
