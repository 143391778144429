import React from "react";
import { useStateContext } from "../../context/StateContext";
import CollectionItem from "../Collectionitem/CollectionItem";
import { CollectioncardContainer } from "./Collectioncard.styled";

const Collectioncard = () => {
  const { collections } = useStateContext();
  return (
    <CollectioncardContainer>
      {collections.map((collection) => (
        <CollectionItem key={collection.id} collection={collection} />
      ))}
    </CollectioncardContainer>
  );
};

export default Collectioncard;
