import { Add, Remove } from "@material-ui/icons";
import isUrl from "is-url";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useStateContext } from "../../context/StateContext";
import { mobileView } from "../../global/responsive";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 30px 20px !important;
  ${mobileView({ padding: "10px !important" })}
`;

const Title = styled.h1`
  font-weight: 600;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px !important;
  ${mobileView({ gap: "10px !important" })}
`;

const TopButton = styled.button`
  padding: 10px !important;
  font-weight: 600;
  cursor: pointer;
  border-color: #b8a06a;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "#b8a06a"};
`;

const TopTexts = styled.div`
  ${mobileView({ display: "none" })}
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px !important;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobileView({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobileView({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
`;

const Details = styled.div`
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductColorContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
`;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
const ProductAnkaraColor = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url(${(props) => props.color});
  margin: 0px 5px !important;
  background-attachment: fixed;
  background-position: center;
  cursor: pointer;
`;
const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px !important;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px !important;
  ${mobileView({ margin: "5px 15px !important" })}
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobileView({ marginBottom: "20px !important" })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 2px solid #b8a06a;
  border-radius: 10px;
  padding: 25px !important;
  min-height: 43vh;
  max-height: 45vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;
`;

const SummaryTitle = styled.h1`
  font-weight: 600;
  padding: 0px 8px !important;
  font-size: 2rem;
`;

const SummaryItem = styled.div`
  margin: 8px 0px !important;
  padding: 10px 8px !important;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;
const SummaryItemContainer = styled.div`
  margin: 0px 0px !important;
  padding: 3px 8px !important;
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;
const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px !important;
  background-color: black;
  color: #b8a06a;
  font-weight: 600;
`;

const Cart = () => {
  const navigate = useNavigate();
  const notify = () => {
    toast("Delivery takes up to 10-15 Business working days.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 10000,
      className: " p-2 m-3",
    });
  };
  const { cartItems, totalPrice, toggleCartItemQuantity, taxAndVat } =
    useStateContext();
  return (
    <Container>
      <Wrapper>
        <Title>YOUR BAG</Title>
        <Top>
          <TopButton
            onClick={() => {
              navigate("/");
            }}
          >
            CONTINUE SHOPPING
          </TopButton>
          <TopTexts>
            <TopText>Shopping Bag({cartItems.length})</TopText>
            <TopText>Your Wishlist (0)</TopText>
          </TopTexts>
          <TopButton
            type="filled"
            onClick={() => {
              navigate(-1);
            }}
          >
            GO BACK{" "}
          </TopButton>
        </Top>
        <Bottom>
          <Info>
            {cartItems.map((product) => (
              <div key={product.id} style={{ margin: "10px 0px !important" }}>
                <Product>
                  <ProductDetail>
                    <Image src={`${product.productImages?.[0]}`} />
                    <Details>
                      <ProductName>
                        <b>Product:</b> {product.productName.toUpperCase()}
                      </ProductName>
                      {isUrl(`${product.color}`) ? (
                        <ProductColorContainer>
                          <b>Color:</b>{" "}
                          <ProductAnkaraColor color={product.color} />
                        </ProductColorContainer>
                      ) : (
                        <ProductColorContainer>
                          <b>Color:</b> <ProductColor color={product.color} />
                        </ProductColorContainer>
                      )}
                      <ProductSize>
                        <b>Size:</b> {product.size}
                      </ProductSize>
                      {product.type && (
                        <ProductSize>
                          <b>Type:</b> {product.type}
                        </ProductSize>
                      )}
                      {product.len && (
                        <ProductSize>
                          <b>Length:</b> {product.len} inch
                        </ProductSize>
                      )}
                    </Details>
                  </ProductDetail>
                  <PriceDetail>
                    <ProductAmountContainer>
                      <Add
                        onClick={() =>
                          toggleCartItemQuantity(
                            product.productName,
                            "increment"
                          )
                        }
                      />
                      <ProductAmount>{product.quantity}</ProductAmount>
                      <Remove
                        onClick={() =>
                          toggleCartItemQuantity(
                            product.productName,
                            "decrement"
                          )
                        }
                      />
                    </ProductAmountContainer>
                    <ProductPrice>₦ {product.productPrice}</ProductPrice>
                  </PriceDetail>
                </Product>
                <Hr />
              </div>
            ))}
          </Info>
          {cartItems.length >= 1 && (
            <Summary>
              <SummaryTitle>ORDER SUMMARY</SummaryTitle>
              <SummaryItemContainer>
                <SummaryItem>
                  <SummaryItemText>Subtotal</SummaryItemText>
                  <SummaryItemPrice>₦{totalPrice}</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Tax & VAT</SummaryItemText>
                  <SummaryItemPrice>₦{taxAndVat}</SummaryItemPrice>
                </SummaryItem>
              </SummaryItemContainer>

              <Button
                onClick={() => {
                  navigate("/checkout");
                  notify();
                }}
              >
                CHECKOUT NOW
              </Button>
            </Summary>
          )}
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default Cart;
