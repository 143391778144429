import styled from "styled-components";
import { mobileView, tabView } from "../../global/responsive";

export const SliderContainer = styled.div`
  width: 100%;
  background: #000;
  height: calc(100vh - 80px);
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobileView({ display: "none" })}
  ${tabView({ display: "none" })}
`;

export const SliderArrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto !important;
  bottom: 0;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

export const SliderWrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

export const SliderSlide = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  gap: 25px;
  align-items: center;
  background-color: #000;
`;

export const SliderImgContainer = styled.div`
  height: 100%;
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;

export const SliderImage = styled.img`
  height: 90%;
  border-radius: 20px;
`;

export const SliderInfoContainer = styled.div`
  flex: 1.5;
  padding: 50px;
`;

export const SliderTitle = styled.h1`
  font-size: 70px;
  color: #b8a06a;
`;

export const SliderDesc = styled.p`
  margin: 30px 0px !important;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #b8a06a;
`;

export const SliderButton = styled.button`
  padding: 10px 20px !important;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  color: #b8a06a;
  border: 3px #b8a06a solid;
`;
