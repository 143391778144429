export const checkServerStatus = () => {
  console.log("Checking Server Status");
  fetch("https://kaizen-v2-backend.onrender.com/api/check/")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Server is offline");
      }
      return response.json();
    })
    .then((data) => console.log(data.message))
    .catch((error) => console.error(error.message))
    .finally(() => {
      // Schedule the next check 14 minutes after the current one completes
      setTimeout(checkServerStatus, 14 * 60 * 1000);
    });
};

let serverUrl;

if (process.env.NODE_ENV === "production") {
  serverUrl = "https://kaizen-v2-backend.onrender.com";
} else {
  serverUrl = "http://localhost:6001";
}

// Use serverUrl to make API calls in your code
export default serverUrl;
