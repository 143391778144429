import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CategoryItemButton,
  CategoryItemContainer,
  CategoryItemImage,
  CategoryItemInfo,
  CategoryItemTitle,
} from "./CategoryItem.styled";

const CategoryItem = ({ category }) => {
  const navigate = useNavigate();
  return (
    <CategoryItemContainer>
      <CategoryItemImage
        src={`${category?.products?.[0]?.productImages?.[0]}`}
      />
      <CategoryItemInfo>
        <CategoryItemTitle>
          {
            category.categoryName
              .split("-") // split the string into an array of words
              .map((word) => word.toUpperCase()) // capitalize the first letter of each word
              .join(" ") // join the words back into a single string with a space separator
          }
        </CategoryItemTitle>
        <CategoryItemButton
          onClick={() => navigate(`/category/${category?.categoryName}`)}
        >
          SHOP NOW
        </CategoryItemButton>
      </CategoryItemInfo>
    </CategoryItemContainer>
  );
};

export default CategoryItem;
