import React from "react";
import styled from "styled-components";
import Typed from "typed.js";
import { mobileView } from "../../global/responsive";

const Container = styled.div`
  height: calc(100vh - 80px);
  background-color: #000;
  color: #b8a06a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  flex-wrap: wrap;
  text-align: center;
  ${mobileView({ height: "60vh" })}
`;
const Featuredpage = () => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);
  // Create reference to store the Typed instance itself
  const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
      strings: [
        "Are you a brand looking to showcase your products ?",
        "Feature and showcase your products with Kaizen Brand",
        "Place your product advert at a low rate ",
        "Featured brands Coming soon......",
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);

  return (
    <Container>
      <div id="typed-texts">
        <p>{<span ref={el} />}</p>
      </div>
    </Container>
  );
};

export default Featuredpage;
