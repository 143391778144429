import styled from "styled-components";
import { mobileView } from "../../global/responsive";

export const NewsletterContainer = styled.div`
  height: 60vh;
  background-color: #fcf5f5;
  color: #b8a06a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const NewsletterTitle = styled.h1`
  font-size: 70px;
`;

export const NewsletterDesc = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px !important;
  ${mobileView({ textAlign: "center" })}
`;

export const NewsletterInputContainer = styled.div`
  width: 50%;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  ${mobileView({ width: "80%" })}
`;

export const NewsletterInput = styled.input`
  border: none;
  flex: 8;
  padding-left: 20px !important;
  color: #b8a06a;
`;

export const NewsletterButton = styled.button`
  flex: 1;
  border: none;
  background-color: #b8a06a;
  color: white;
`;
