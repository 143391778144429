import styled from "styled-components";
import { mobileView } from "../../global/responsive";

export const ProductListContainer = styled.div`
  background-color: #000;
  color: #b8a06a;
`;

export const ProductListTitle = styled.h1`
  padding-top: 20px !important;
  text-align: center;
`;

export const ProductListFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProductListFilter = styled.div`
  margin: 20px !important;
  ${mobileView({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

export const ProductListFilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px !important;
  ${mobileView({ marginRight: "0px  !important" })}
`;

export const ProductListSelect = styled.select`
  padding: 10px !important;
  margin-right: 20px !important;
  ${mobileView({ margin: "10px 0px !important" })}
`;
export const ProductListOption = styled.option``;
