import { Nav } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import styled from "styled-components";
import { mobileView, tabView } from "../../global/responsive";
import { css } from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Menu } from "@material-ui/icons";
import { ShoppingCartOutlined } from "@material-ui/icons";

export const NavContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: black;
  color: #b8a06a;
`;
export const NavWrapper = styled.div`
  height: 100%;
  padding: 10px 20px !important;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
`;
export const NavLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobileView({ justifyContent: "flex-start !important" })}
`;
export const NavbarToggle = styled(Navbar.Toggle)`
  color: #b8a06a;
  font-size: 2rem;
  border: none;
  &:focus,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
export const NavbarMenuIcon = styled(Menu)`
  font-size: 3rem !important;
`;
export const NavbarOffcanvasHeader = styled(Offcanvas.Header)`
  padding: 20px !important;
  background: black;
  font-size: 2rem;
`;
export const NavbarOffcanvasTitle = styled(Offcanvas.Title)`
  font-size: 2rem;
`;
export const NavbarOffcanvasBody = styled(Offcanvas.Body)`
  display: flex;
  justify-content: flex-end;
  background: black;
  ${mobileView({ justifyContent: "flex-start" })}
  ${tabView({
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "20px !important",
  })};
`;
export const NavbarNav = styled(Nav)`
  display: flex;
  justify-content: flex-end !important;
  padding: 0px 25px !important;
  gap: 20px;
`;
export const NavLink = styled(Link)`
  color: #b8a06a;
  text-decoration: none;
  ${tabView({ fontSize: "1.5rem" })}
  &:focus {
    color: #b8a06a;
  }
  &:hover {
    color: #b8a06a;
  }
`;
export const activeNavLink = css`
  border-bottom: 2px #b8a06a solid;
`;

export const NavCenter = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const NavLogo = styled.img`
  width: 20%;
  cursor: pointer;
  ${tabView({ width: "40%" })}
  ${mobileView({ width: "70%" })}
`;
export const NavRight = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobileView({ justifyContent: "flex-end" })}
`;
export const NavShoppingCartOutlined = styled(ShoppingCartOutlined)`
  font-size: 2.3rem !important;
`;
