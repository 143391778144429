import React from "react";
import Collectioncard from "../../components/Collectioncard/Collectioncard";

const Collectionpage = () => {
  return (
    <>
      <Collectioncard />
    </>
  );
};

export default Collectionpage;
