import { Badge } from "@material-ui/core";
import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import {
  NavCenter,
  NavContainer,
  NavLeft,
  NavLink,
  NavLogo,
  NavRight,
  NavShoppingCartOutlined,
  NavWrapper,
  NavbarMenuIcon,
  NavbarNav,
  NavbarOffcanvasBody,
  NavbarOffcanvasHeader,
  NavbarOffcanvasTitle,
  NavbarToggle,
} from "./Navbar.styled";

const MainNavbar = () => {
  const navigate = useNavigate();
  const expand = "lg";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { totalQuantities } = useStateContext();
  return (
    <Navbar
      onSelect={true}
      expand="lg"
      style={{ margin: "0px auto" }}
      sticky="top"
    >
      <NavContainer>
        <NavWrapper>
          <NavLeft>
            <NavbarToggle
              aria-controls="kaizen-brand-navbar-offcanvas"
              onClick={handleShow}
            >
              <NavbarMenuIcon />
            </NavbarToggle>
            <Navbar.Offcanvas
              style={{ background: "black", color: "#b8a06a" }}
              show={show}
              onHide={handleClose}
              responsive="lg"
              id={`kaizen-brand-navbar-offcanvas`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <NavbarOffcanvasHeader
                closeButton
                closeVariant="white"
                closeLabel="10"
              >
                <NavbarOffcanvasTitle id={`kaizen-brand-navbar-offcanvas`}>
                  KAIZEN
                </NavbarOffcanvasTitle>
              </NavbarOffcanvasHeader>
              <NavbarOffcanvasBody>
                <NavbarNav>
                  <NavLink to="/" onClick={() => setShow(!show)}>
                    Home
                  </NavLink>
                  <NavLink to="/collections" onClick={() => setShow(!show)}>
                    Collections
                  </NavLink>
                  {/* <NavLink to="/collections" onClick={() => setShow(!show)}>
                    Design&nbsp;with&nbsp;Kaizen&nbsp;🎨
                  </NavLink> */}
                  <NavLink to="/featured-brands" onClick={() => setShow(!show)}>
                    Featured&nbsp;Brands
                  </NavLink>
                </NavbarNav>
              </NavbarOffcanvasBody>
            </Navbar.Offcanvas>
          </NavLeft>

          <NavCenter>
            <NavLogo
              src="https://i.ibb.co/5hdjR0z/kenny-1.png"
              alt="Kaizen Logo"
              onClick={() => navigate("/#")}
            />
          </NavCenter>
          <NavRight>
            <Badge
              badgeContent={totalQuantities}
              style={{ fontSize: "2rem" }}
              color="primary"
            >
              <NavShoppingCartOutlined onClick={() => navigate("/cart")} />
            </Badge>
          </NavRight>
        </NavWrapper>
      </NavContainer>
    </Navbar>
  );
};

export default MainNavbar;
