import React from "react";
import Categories from "../../components/Category/Categories";
// import Products from "../../components/Products/Products";
import { ProductsContainer } from "../../components/Products/Products.styled";
import Slider from "../../components/Slider/Slider";
import { SwiperSlider } from "../../components/Swiperslider/Swiperslider";
import { useStateContext } from "../../context/StateContext";
import { H2 } from "./Homepage.styled";

const Homepage = () => {
  const { products } = useStateContext();
  const bestSellingProducts = products.filter((product) => {
    return product.isBestSelling === true;
  });
  console.log(bestSellingProducts);
  return (
    <>
      <Slider />
      <Categories />
      <ProductsContainer paddingTop={60}>
        <H2>Best Selling Products</H2>
        <SwiperSlider products={bestSellingProducts} />
      </ProductsContainer>
    </>
  );
};

export default Homepage;
