import {
  FavoriteBorderOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import React from "react";
import {
  ProductContainer,
  ProductIcon,
  ProductImage,
  ProductImgContainer,
  ProductInfo,
  ProductInfoContainer,
  ProductLink,
  ProductName,
  ProductPrice,
} from "./Product.styled";

const Product = ({ product }) => {
  return (
    <ProductLink to={`/${product?.productName}/details`}>
      <ProductContainer>
        <ProductImgContainer>
          <ProductImage src={`${product?.productImages?.[0]}`} />
        </ProductImgContainer>
        <ProductInfo>
          <ProductIcon>
            <ShoppingCartOutlined />
          </ProductIcon>
          <ProductIcon>
            <FavoriteBorderOutlined />
          </ProductIcon>
        </ProductInfo>
        <ProductInfoContainer>
          <ProductName>{product?.productName}</ProductName>
          <ProductPrice>₦{product?.productPrice}</ProductPrice>
        </ProductInfoContainer>
      </ProductContainer>
    </ProductLink>
  );
};

export default Product;
