import React from "react";
import {
  FooterCenter,
  FooterContactItem,
  FooterContainer,
  FooterDesc,
  FooterLeft,
  FooterList,
  FooterLogo,
  FooterPayment,
  FooterRight,
  FooterSocialContainer,
  FooterSocialIcon,
  FooterTitle,
  FooterStyleGuideTitle,
  StyleGuideContainer,
  FooterStyleGuideImage,
} from "./Footer.styled";
import {
  Instagram,
  MailOutline,
  Phone,
  Room,
  Twitter,
  WhatsApp,
} from "@material-ui/icons";
import { NavLink } from "../Navbar/Navbar.styled";

const Footer = () => {
  return (
    <>
      <StyleGuideContainer>
        <FooterStyleGuideTitle>Our Size Guide</FooterStyleGuideTitle>
        <FooterStyleGuideImage
          className="img-fluid"
          src="https://i.ibb.co/fFsP8Hm/Whats-App-Image-2022-09-09-at-3-56-18-PM-1.jpg"
          alt="Style Guide"
        />
      </StyleGuideContainer>
      <FooterContainer>
        <FooterLeft>
          <FooterLogo>K A I Z E N</FooterLogo>
          <FooterDesc>
            Making life easier for the masses by producing top notch services to
            our clients. We aim for a classy and professional perception while
            also being stylish, reliable and memorable.
          </FooterDesc>
          <FooterSocialContainer>
            <FooterSocialIcon href="https://api.whatsapp.com/send?phone=2349054210115">
              <WhatsApp />
            </FooterSocialIcon>
            <FooterSocialIcon href="https://www.instagram.com/kaizen.brand">
              <Instagram />
            </FooterSocialIcon>
            <FooterSocialIcon href="https://twitter.com/kaizenbrand_">
              <Twitter />
            </FooterSocialIcon>
          </FooterSocialContainer>
        </FooterLeft>
        <FooterCenter>
          <FooterTitle>Quick links</FooterTitle>
          <FooterList>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/collections">Collections</NavLink>
            <NavLink to="/featured-brands">Featured brands</NavLink>
          </FooterList>
        </FooterCenter>
        <FooterRight>
          <FooterTitle>Contact</FooterTitle>

          <FooterContactItem>
            <Room />
            <span>12, Abudu Street, Abule Oja, Lagos. state, Nigeria</span>
          </FooterContactItem>
          <FooterContactItem>
            <Phone />
            <span>+2349054210115</span>
          </FooterContactItem>
          <FooterContactItem>
            <MailOutline />
            <span>kaize.brand@gmail.com</span>
          </FooterContactItem>
          <FooterPayment src="https://i.ibb.co/Qfvn4z6/payment.png" />
        </FooterRight>
      </FooterContainer>
    </>
  );
};

export default Footer;
