import { Add, Remove } from "@material-ui/icons";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useStateContext } from "../../context/StateContext";
import { mobileView, tabView } from "../../global/responsive";
const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px !important;
  display: flex;
  align-items: center;
  gap: 20px;
  ${tabView({
    padding: "10px !important",
    flexDirection: "column !important",
  })}
`;

const ImgContainer = styled.div`
  flex: 1 !important;
`;

const Image = styled.img`
  width: 100%;
  height: 90vh;
  object-fit: cover !important;
  ${mobileView({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px !important;
  ${tabView({ padding: "10px !important" })};
`;

const Title = styled.h1`
  font-weight: 600;
`;

const Desc = styled.p`
  margin: 20px 0px !important;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 40px;
`;

const FilterContainer = styled.div`
  width: 50% !important;
  margin: 30px 0px !important;
  display: flex;
  justify-content: space-between !important;
  flex-wrap: wrap;
  gap: 10px;
  ${tabView({ width: "100% !important" })}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin: 0px 5px !important;
  cursor: pointer;
  &.clicked {
    border: 2px solid #b8a06a !important;
  }
`;
// const FilterAnkaraColor = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background: url(${(props) => props.color});
//   margin: 0px 5px !important;
//   background-attachment: fixed;
//   background-position: center;
//   cursor: pointer;
//   &.clicked {
//     border: 2px solid #b8a06a !important;
//   }
// `;

const FilterSize = styled.select`
  margin-left: 10px !important;
  padding: 5px !important;
`;

const FilterSizeOption = styled.option``;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  ${tabView({ width: "100%" })}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 4px;
  ${tabView({ gap: "9px" })}
`;

const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #b8a06a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px !important;
`;

const Button = styled.button`
  padding: 15px !important;
  border: 2px solid #b8a06a;
  background-color: white;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #f8f4f4;
  }
`;

const Productdetail = () => {
  const { productName } = useParams();
  const [color, setColor] = useState("black");
  const [size, setSize] = useState("XS");
  const [type, setType] = useState("Button");
  const [trouserLen, setTrouserLen] = useState(35);
  const [colorName, setColorName] = useState("");
  const { products } = useStateContext();
  console.log(products);
  const product = products.find((item) => item.productName === productName);
  console.log(product);
  const [imageIndex, setImageIndex] = useState(0);
  const productImageUrlLength = product.productImages.length - 1;

  const { qty, increaseQty, decreaseQty, onAddToCart } = useStateContext();

  useEffect(() => {
    const interval = setInterval(() => {
      if (imageIndex < productImageUrlLength) {
        return setImageIndex((prevState) => prevState + 1);
      } else if (imageIndex >= productImageUrlLength) {
        return setImageIndex(0);
      } else {
        return setImageIndex(0);
      }
    }, 5500);
    return () => clearInterval(interval);
  }, [imageIndex, productImageUrlLength]);
  const availableColors = [
    { name: "Navy Blue", hex: "#000080" },
    { name: "Brown", hex: "brown" },
    { name: "Army Green", hex: "#4b5320" },
    { name: "Dark Gray", hex: "darkgray" },
    { name: "Light Gray", hex: "lightgray" },
    { name: "Royal Blue", hex: "#3f66da" },
    { name: "Black", hex: "#000" },
    { name: "Cream", hex: "#fffdd0" },
  ];
  const bucketHatsColor = [
    { name: "Black", hex: "#000" },
    { name: "White", hex: "whitesmoke" },
  ];
  const bagsColor = [
    { name: "Black", hex: "#000" },
    { name: "Blue", hex: "#000080" },
  ];
  // const ankaraJacketsPatterns = [
  //   {
  //     name: "Ankara Pattern 1",
  //     hex: "https://i.ibb.co/bPWyh4t/wowpatterns-export.png",
  //   },
  //   {
  //     name: "Ankara Pattern 2",
  //     hex: "https://i.ibb.co/BNJ6LLB/wowpatterns-export-1.png",
  //   },
  //   {
  //     name: "Ankara Pattern 3",
  //     hex: "https://i.ibb.co/D8bwPGZ/wowpatterns-export-2.png",
  //   },
  //   {
  //     name: "Ankara Pattern 4",
  //     hex: "https://i.ibb.co/hDvv2m3/wowpatterns-export-3.png",
  //   },
  // ];
  const trouserLength = [
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
  ];
  const availableSizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL"];
  const poloType = ["Button", "Zipper"];
  return (
    <Container>
      <Wrapper>
        <ImgContainer>
          <Image src={`${product.productImages?.[imageIndex]}`} />
        </ImgContainer>
        <InfoContainer>
          <Title>{product.productName}</Title>
          <Desc>{product.description}</Desc>
          <Price>₦{product.productPrice}</Price>
          <FilterContainer>
            <Filter>
              <FilterTitle>Color:</FilterTitle>
              {product?.productType === "bucket-hat"
                ? bucketHatsColor.map((color) => (
                    <FilterColor
                      key={color.name}
                      color={color.hex}
                      onClick={() => {
                        setColor(color.hex);
                        setColorName(color.name);
                        toast.success(`${color.name} selected successfully`);
                      }}
                    />
                  ))
                : product.productType === "bag"
                ? bagsColor.map((color) => (
                    <FilterColor
                      key={color.name}
                      color={color.hex}
                      onClick={() => {
                        setColor(color.hex);
                        setColorName(color.name);
                        toast.success(`${color.name} selected successfully`);
                      }}
                    />
                  ))
                : availableColors.map((color) => (
                    <FilterColor
                      key={color.name}
                      color={color.hex}
                      onClick={() => {
                        setColor(color.hex);
                        setColorName(color.name);
                        toast.success(`${color.name} selected successfully`);
                      }}
                    />
                  ))}
            </Filter>
            <Filter>
              <FilterTitle>Size:</FilterTitle>
              <FilterSize
                onChange={(e) => {
                  setSize(e.target.value);
                  toast.success(`${e.target.value} selected successfully`);
                }}
              >
                {availableSizes.map((size) => (
                  <FilterSizeOption key={size}>{size}</FilterSizeOption>
                ))}
              </FilterSize>
            </Filter>

            {product.productType === "polo" ? (
              <Filter>
                <FilterTitle>Type:</FilterTitle>
                <FilterSize
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  {poloType.map((typeofPoloNeck) => (
                    <FilterSizeOption key={typeofPoloNeck}>
                      {typeofPoloNeck}
                    </FilterSizeOption>
                  ))}
                </FilterSize>
              </Filter>
            ) : null}

            {product.productType === "joggers-and-sweatpant-and-cargos" ? (
              <Filter>
                <FilterTitle>Length:</FilterTitle>
                <FilterSize
                  onChange={(e) => {
                    setTrouserLen(e.target.value);
                  }}
                >
                  {trouserLength.map((trouserL) => (
                    <FilterSizeOption key={trouserL}>
                      {trouserL}
                    </FilterSizeOption>
                  ))}
                </FilterSize>
              </Filter>
            ) : null}
          </FilterContainer>
          <AddContainer>
            <AmountContainer>
              <Remove onClick={decreaseQty} />
              <Amount>{qty}</Amount>
              <Add onClick={increaseQty} />
            </AmountContainer>
            <Button
              onClick={() =>
                onAddToCart(
                  product,
                  qty,
                  color,
                  colorName,
                  size,
                  product.productType === "joggers-and-sweatpant-and-cargos" &&
                    trouserLen,
                  product.productType === "polo" && type
                )
              }
            >
              ADD TO CART
            </Button>
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default Productdetail;
