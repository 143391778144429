import styled from "styled-components";

export const ProductsContainer = styled.div`
  background: #000;
  padding: ${(props) => props.paddingTop}px 40px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 20px;
  justify-content: center !important;
`;
