import React from "react";
import { Send } from "@material-ui/icons";
import {
  NewsletterButton,
  NewsletterContainer,
  NewsletterDesc,
  NewsletterInput,
  NewsletterInputContainer,
  NewsletterTitle,
} from "./Newsletter.styled";

const Newsletter = () => {
  return (
    <NewsletterContainer>
      <NewsletterTitle>Newsletter</NewsletterTitle>
      <NewsletterDesc>
        Get the latest kaizen monologues updates directly in your mail .
      </NewsletterDesc>
      <NewsletterInputContainer>
        <NewsletterInput placeholder="Enter your E-mail" />
        <NewsletterButton>
          <Send />
        </NewsletterButton>
      </NewsletterInputContainer>
    </NewsletterContainer>
  );
};

export default Newsletter;
