import React, { useState } from "react";

const Dresser = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const [visible, setVisible] = useState(
    urlParams.get("showDresser") === "true"
  );

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          padding: "2rem!important",
          display: visible ? "flex" : "none",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          zIndex: 1020,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            padding: "10px!important",
            borderRadius: "10px!important",
            width: "85%",
            height: "calc(100% - 81px)",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              padding: "10px",
              position: "relative",
            }}
          >
            <button
              onClick={() => setVisible(false)}
              style={{
                backgroundColor: "transparent",
                color: "#fa5305",
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translate(-15px, 5px)",
                fontSize: "1.8rem",
                width: "2.5rem",
                height: "2.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
              }}
            >
              &times;
            </button>
          </div>
          <iframe
            src="https://stylrr.kaizenbrand.shop/"
            title="dresser"
            style={{
              display: "block",
              width: "100%",
              border: "none",
              overflowY: "auto",
              height: "100%",
            }}
          ></iframe>
        </div>
      </div>
      <div
        style={{
          background: "white",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          borderRadius: "100%",
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 1015,
          transform: "translate(-10px, -15px)",
          boxShadow:
            "0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3)",
        }}
      >
        <button
          id="dressingRoomButton"
          style={{
            background: "white",
            border: "3px solid rgb(184, 160, 106)",
            borderRadius: "100%",
            fontSize: ".875rem",
            height: "80px",
            width: "80px",
            textAlign: "center",
            wordBreak: "break-word",
            display: "flex",
            fontWeight: 600,
            justifyContent: "center",
            alignItems: "center",
            color: "black",
          }}
          onClick={() => setVisible(true)}
        >
          Dressing Room
        </button>
      </div>
    </div>
  );
};

export default Dresser;
