import styled from "styled-components";
import { tabView } from "../../global/responsive";

export const CollectioncardContainer = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  padding: 30px !important;
  gap: 20px;
  justify-content: center !important;
  ${tabView({ padding: "0px", flexDirection: "column" })}
`;
